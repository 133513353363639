<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
        <v-button text="重置" @click="reSet"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="标题" v-model="searchParam.topicTitle" />
        <v-select
          clearable
          placeholder="请选择"
          :options="status"
          v-model="searchParam.status"
          @change="$refs.list.search()"
          label="发布状态"
        />
        <v-select
          clearable
          placeholder="请选择"
          :options="isTop"
          v-model="searchParam.closePraise"
          @change="$refs.list.search()"
          label="是否点赞"
        />
        <v-select
          clearable
          placeholder="请选择"
          :options="isTop"
          v-model="searchParam.closeComment"
          @change="$refs.list.search()"
          label="是否评价"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="toEdit(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  deleteTopicURL,
  addTopicUrl,
  updateTopicUrl,
  getTopicListUrl,
  getTopicInfoByIdUrl,
} from "./api.js";
import {
  status,
  statusMap,
  setStatusOps,
  isTop,
  isTopMap,
  setIsTopOps,
} from "./map.js";
import { createImgVNode } from "@/utils/utils.js";
export default {
  name: "onLineStudy",
  data() {
    return {
      status,
      isTop,
      searchParam: {
        topicTitle: "",
        status: "",
        closePraise: "",
        closeComment: "",
        topicType: "18",
      },
      tableUrl: getTopicListUrl,
      headers: [
        {
          prop: "isTopSort",
          label: "排序",
        },
        {
          prop: "topicPicture",
          label: "封面",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "topicTitle",
          label: "标题",
        },
        {
          prop: "createTime",
          label: "发表日期",
        },
        {
          prop: "closePraise",
          label: "是否关闭点赞",
          formatter: (row, prop) => {
            return isTopMap[row.closePraise];
          },
        },
        {
          prop: "closeComment",
          label: "是否关闭评论",
          formatter: (row, prop) => {
            return isTopMap[row.closeComment];
          },
        },
        {
          prop: "status",
          label: "发布状态",
          formatter: (row, prop) => {
            return statusMap[row.status];
          },
        },
      ],
    };
  },
  methods: {
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: item.id,
        };
        this.$axios
          .post(`${deleteTopicURL}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    toAdd() {
      this.$router.push({
        name: "onLineStudyForm",
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "onLineStudyForm",
        query: {
          id: row.id,
        },
      });
    },
    reSet() {
      this.searchParam.topicTitle = "";
      this.searchParam.status = "";
      this.searchParam.closePraise = "";
      this.searchParam.closeComment = "";
      this.searchParam.topicType = "15";
    },
  },
};
</script>

<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
